import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { mq, remCalc, vwMinMax } from '../../stylehelpers/mixins';
import { breakpoint, fontFamily, color } from '../../stylehelpers/variables';

const List = styled.dl`
  ${mq.medium`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-auto-rows: 1fr;
    grid-gap: 2em;
  `};
  margin-bottom: 25%;
`;

const Key = styled.dt`
  font-variant: normal;
  ${vwMinMax('font-size', 14, 18, breakpoint.medium)}
  font-weight: bold;
  font-family: ${fontFamily.base};
`;

const Value = styled.dd`
  font-variant: normal;
  ${vwMinMax('font-size', 16, 20, breakpoint.medium)}
  margin-bottom: ${remCalc(20)};
  font-family: ${fontFamily.base};
  ${mq.medium`margin: 0;`}

  a {
    color: ${color.text}!important;
    font-family: ${fontFamily.base};
  }
`;

const DataList = ({ data }) => (
  <List>
    {data.map(item => (
      <>
        <Key key={`${item.key}-key`}>{item.key}</Key>
        <Value key={`${item.key}-value`}>{item.value}</Value>
      </>
    ))}
  </List>
);

DataList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ).isRequired,
};

export default DataList;
