import React from 'react';
import { address, company } from '../../data.yaml';
import { Container, Layout, LinkWrapper } from '../components';
import DataList from '../components/datalist/datalist';
import { mq } from '../stylehelpers/mixins';
import { formatTel } from '../utils';
import styled from 'styled-components';

import Logo from '../images/respkt-logo.svg';

const StyledImage = styled.img`
  max-width: 150px;
  ${mq.medium`
        max-width: 200px;
    `}

  margin-bottom: 40px;
  margin-top: 20px;
  display: block;
`;

const ImprintPage = () => (
  <Layout address={address} title="Impressum">
    <Container sidePadding={15} width={1200} center mb="200px">
      <LinkWrapper to="/">
        <StyledImage src={Logo}></StyledImage>
      </LinkWrapper>
      <DataList
        data={[
          {
            key: 'Herausgeber',
            value: (
              <address>
                {company.name}
                <br />
                {company.street}
                <br />
                {company.zip} {company.city}
                <br />
                Tel:{' '}
                <LinkWrapper fontSize="m" to={`tel:${address.tel}`}>
                  {formatTel(address.tel)}
                </LinkWrapper>
                <br />
                Fax: {formatTel(address.fax)}
                <br />
                E-Mail:{' '}
                <LinkWrapper fontSize="m" to={`mailto:${address.mail}`}>
                  {address.mail}
                </LinkWrapper>
                <br />
              </address>
            ),
          },
          {
            key: 'Vertretungsberechtigte Geschäftsführer',
            value: company.managers.join(', '),
          },
          {
            key: 'Sitz der Gesellschaft',
            value: company.headquarters,
          },
          {
            key: 'HRB',
            value: `${company.register.id} ${company.register.registry}`,
          },
          {
            key: 'Umsatzsteuer-ID-Nr. gem. §27a UstG',
            value: company.vatId,
          },
          {
            key: 'Inhaltlich verantwortlich',
            value: company.responsible,
          },
        ]}
      />
    </Container>
  </Layout>
);

export default ImprintPage;
